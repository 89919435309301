import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { useParams } from 'react-router-dom';
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import APIService from "../../services/APIService";
import riderAvtSec from "../../assest/images/carimage.png";
import { Data , ModalData , Manufacturer , Modalyear , TotalCars , ActiveInactive , CarDetailTable} from "./Data";
import CarInventoryHeader from "./components/CarInventoryHeader";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_RIDE_A_RIDE_URL;
const getInventoryById = `${baseURL}/api/ride/inventory/get`;
const secretKey = process.env.REACT_APP_RIDE_A_RIDE_SECRET_KEY;


let RidearideDetails = (props) => {
  const {id} = useParams();
  console.log(id);
  let [inventoryDetails, setInventoryDetails] = useState({}),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [showFilter, setShowFilter] = useState(false),
    [subStatus, setSubStatus] = useState(null),
    dt = useRef(null),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    [lazyParams, setLazyParams] = useState({
      filters: {},
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    [createdAt, setCreatedAt] = useState([]),
    getDrivers = async() => {
      try{
        let callApi = await fetch(`${getInventoryById}?inventory_id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Origin": "*",
            token: secretKey,
          },
        });
        let response = await callApi.json();
        // console.log(response);
        setInventoryDetails(response?.data)
      }catch(e){
        console.warn(e);
        toast.warn("Something Went Wrong");
      }
    },
    manageDrivers = (event) => {
      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          if (event.filters[filter].value) {
            if (filter === "totalTrips") {
              lazyParams.filters[filter] = Number(event.filters[filter].value);
            } else {
              lazyParams.filters[filter] = event.filters[filter].value;
            }
          }
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      setLazyParams(lazyParams);
      getDrivers();
    },
    statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
    renderHeader = () => {
      return (
        <div className="table-header">
          <span className="toggle-filter">
            <button
              className="btn btn-primary show_filter_btn"
              onClick={() => toggleFilter()}
            >
              {windowWidth ? (
                showFilter ? (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-up ml-10"></i>
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-filter"></i>
                    <i className="fa fa-caret-down ml-10"></i>
                  </span>
                )
              ) : // <i className="fa fa-filter"></i>
                showFilter ? (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <i className="fa fa-caret-up  "></i>
                  </>
                ) : (
                  <>
                    <img src="/images/filter-icon.svg" alt="" srcSet="" />
                    <span className="span-filter mx-12">Filters</span>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                        fill="#828282"
                      />
                    </svg>
                  </>
                )}
            </button>
          </span>
          <span className="p-input-icon-left p-captain d-flex align-items-center">
            <i className="pi pi-search" />
            <InputText
              type="search"
              placeholder="Search"
              value={lazyParams.keyword}
              onInput={(e) => {
                lazyParams = {
                  ...lazyParams,
                  skip: 0,
                  take: 25,
                  keyword: e.target.value,
                };
                setLazyParams(lazyParams);
                // getDrivers();
              }}
            />
          </span>
        </div>
      );
    },
    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {},
        sort: {
          field: "createdAt",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
      });
      setCreatedAt([]);
      setSubStatus(null);
      getDrivers();
    };


  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <div className="rider-listing">
                <div>
                <span style={{ cursor: "pointer" }}>
          {
            <Link
              to={"/car-details"}
              className="dataFnt-size"
              style={{ color: "rgba(19, 181, 66, 1)" }}
            >
              <i className="fa fa-chevron-left back-arrow"></i>
            </Link>
          }
        </span>
          <span className="title">Car Inventory</span>
        </div>
    <div className="datatable-doc-demo mt-3">
      <div className="card">
        <CarInventoryHeader inventoryData={inventoryDetails}/>
        </div>
        <div className="card mt-3">
        <DataTable
          ref={dt}
          value={CarDetailTable}
          header={header}
          className="p-datatable-customers"
          dataKey="id"
          rowHover
          scrollable
          scrollHeight="470px"
          paginator
          emptyMessage="No Driver(s) found"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          rowsPerPageOptions={[10, 25, 50]}
          totalRecords={CarDetailTable.length}
          lazy={true}
          first={lazyParams.skip}
          rows={lazyParams.take}
          sortField={lazyParams.sort.field}
          sortOrder={lazyParams.sort.order}
          onPage={manageDrivers}
          onSort={manageDrivers}
          onFilter={manageDrivers}
          // loading={useSelector((state) => state.isGridLoader)}
          loadingIcon="fa fa-spinner"
        >

          <Column
            className="text-center"
            headerClassName="text-center"
            style={{ fontSize: "12px", width: "120px" }}
            header="Sequence no."
            field="sequenceno"
            sortable
            filter={showFilter}
            filterMatchMode="custom"
            // filterFunction={customFunction}
            // filterPlaceholder="Search by name"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain ID"
            field="captainid"
            sortable
            filter={showFilter}
            // filterPlaceholder="Search by Services"
          />
                    <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Captain Name"
            field="captainname"
            sortable
            filter={showFilter}
            // filterPlaceholder="Search by Services"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Subscription Start Date"
            field="subscriptionstartdate"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by Loyalty points"
          />

          <Column
            className="text-center"
            style={{ fontSize: "12px", width: "180px" }}
            header="Subscription End Date"
            field="subscriptionenddate"
            sortable
            filter={showFilter}
            filterPlaceholder="Search by mobile"
          />

        </DataTable>
      </div >
    </div >
    </div>
  );
};

export default React.memo(connect()(withRouter(RidearideDetails)));
