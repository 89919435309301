import React, { useState, useEffect, useRef } from "react";
import { CalendarRange } from "react-bootstrap-icons";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { DataTable } from "primereact/datatable";
import CountryInput from "./InputFeild";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import Rating from "react-rating";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import APIService from "../../services/APIService";
import { InputNumber } from "primereact/inputnumber";
import rideSource from "../../assest/images/ride-app-source.svg";
import whatsappSource from "../../assest/images/ride-app-source.svg";
import riderAvtSec from "../../assest/images/placeHoderImage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { data } from "jquery";
import { replace } from "lodash";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CSVLink } from "react-csv";

let RiderListingTable = (props) => {
  let [status, setStatus] = useState(null),
    [otp, setotp] = useState(""),
    [moblNo, setMoblNo] = useState("966"),
    [OTPValidation, setOTPValidation] = useState(null),
    [tillid, setTillId] = useState(""),
    [minutes, setMinutes] = useState(0),
    [seconds, setSeconds] = useState(0),
    [enableOTP, setEnableOTP] = useState(false),
    [sendotp, setSendotp] = useState(true),
    [showRider, setAddRider] = useState(false),
    [showMessage, setMessage] = useState(false),
    [filterSearch, setFilterSearch] = useState(null),
    CSVdownloadRef = useRef(null),
    [showFilter, setShowFilter] = useState(false),
    // [tabledata,setTableData]=useState([
    //   {
    //     id: "",
    //     fullName: "",
    //     overallRating: "",
    //     layaltyPoints: "",
    //     mobileNo: "",
    //     createdAt: "",
    //     totalRides: "",
    //     kycStatus: "",
    //     Active: "",
    //     Rider: "",
    //   },
    // ]),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    currentRoutePermissions = permissions.filter(a => a.includes('/riders/')),
    dt = useRef(null),
    [windowWidth] = useState(useSelector((state) => state.windowSize)),
    [creationDate, setCreationDate] = useState([]),
    [riders, setRiders] = useState([]),
    [isCalendarOpen, setIsCalendarOpen] = useState(false),
    [downlaodCsv, setDownloadCsv] = useState([]),
    [fillRider, setFillRider] = useState([]),
    [lazyParams, setLazyParams] = useState({
      filters: {
        userType: 1,
      },
      sort: {
        field: "createdAt",
        order: -1,
      },
      take: 25,
      skip: 0,
      keyword: "",
      totalRecords: 0,
    }),
    getRiders = () => {
      props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: true });
      let filterParams = JSON.parse(JSON.stringify(lazyParams));
      filterParams.sort.order = filterParams.sort.order === -1 ? "desc" : "asc";
      APIService.post("admin/riders", filterParams)
        .then((response) => {
          if (response.data && response.data.statusCode === 200) {
            lazyParams.totalRecords = response.data.data.totalCount;
            setLazyParams(lazyParams);

            const Response = response.data.data.riders;
            var filterResponse = Response.map((item) => {
              return (
                item.userStatus === "A"
                  ? (item.userStatus = "Active")
                  : item.userStatus == null
                    ? (item.userStatus = "Inactive")
                    : "Locked",

                (item.createdAt = moment(item.createdAt).format(
                  "DD/MM/YYYY - hh:mm A"
                ))

              );
            });


            setFillRider(filterResponse);
            setRiders(Response);


            props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
          }
        })
        .catch((error) => {
          props.dispatch({ type: "TOGGLE_GRID_LOADER", payload: false });
        });
    },
    manageRiders = (event) => {

      if (event.sortField) {
        lazyParams.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      } else if (event.first || event.first === 0) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = event.first;
      } else if (event.filters && Object.keys(event.filters).length) {
        lazyParams.take = event.rows ? event.rows : lazyParams.take;
        lazyParams.skip = 0;
        let filters = { ...lazyParams.filters };
        for (let filter in event.filters) {
          lazyParams.filters[filter] = event.filters[filter].value;
        }
        lazyParams.filters = { ...filters, ...lazyParams.filters };
      } else if (!event.filters || !Object.keys(event.filters).length) {
        lazyParams.filters = {};
      }
      lazyParams.filters['userType'] = 1
      setLazyParams(lazyParams);
      getRiders();
    };
  let addRiderHandleClose = () => {
    setAddRider(false)
    setEnableOTP(false)
  },
    addRiderHandleShow = () => {
      setAddRider(true);
    },
    handleCloseMessage = () => {
      setMessage(false);
      setMinutes(0);
      setSeconds(0);
    },
    handleShowMessage = () => {
      setMessage(true);
    };
    let ratingCount = (value) => {
      const statusText = ["-", "😣 Poor", "😔 Bad", "🙂 Normal", "😀 Good", "😊 Excellent"];
      return statusText[value] || "-";
    };
    
  // let ratingCount = (value) => {
  //   let statusText;
  //   switch (value) {
  //     case 1:
  //       statusText = "😣 Poor";
  //       break;
  //     case 2:
  //       statusText = "😔 Bad";
  //       break;
  //     case 3:
  //       statusText = "🙂 Normal";
  //       break;
  //     case 4:
  //       statusText = "😀 Good";
  //       break;
  //     case 5:
  //       statusText = "😊 Excellent";
  //       break;
  //     default:
  //       statusText = "-";
  //       break;
  //   }
  //   return statusText;
  // };

  const onSearchFilter = (e) => {
    const value = e.target.value;
    const filteredByID = riders?.filter((name) => name.userId.includes(value));
    setShowFilter(filteredByID);

    // return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
  };

  useEffect(() => {
    getRiders();
  }, []);
  const headers = [
    { label: 'ID', key: 'userId' },
    { label: 'Full Name', key: 'fullName' },
    { label: 'Rating', key: 'overallRating' },
    { label: 'Loyailty Points', key: 'LoyaltyPoints' },
    { label: 'Moblie No', key: 'mobileNo' },
    { label: 'Data & Time', key: 'createdAt' },
    { label: 'completed Rides', key: 'totalRides' },
    { label: 'KYC', key: 'kyc' },
    { label: 'Status', key: 'userStatus' }
  ]

  let statusOptions = [
    { value: "A", label: "Active" },
    { value: "B", label: "Locked" },
    { value: "I", label: "Inactive" },
  ],
    renderHeader = () => {
      return (
        <>
          <div className="table-header">
            <span className="toggle-filter">
              <button
                className="btn btn-primary show_filter_btn"
                onClick={() => toggleFilter()}
              >
                {windowWidth ? (
                  showFilter ? (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-up ml-10"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="fa fa-filter"></i>
                      <i className="fa fa-caret-down ml-10"></i>
                    </span>
                  )
                ) : // <i className="fa fa-filter"></i>
                  showFilter ? (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <i className="fa fa-caret-up"></i>
                    </>
                  ) : (
                    <>
                      <img src="/images/filter-icon.svg" alt="" />
                      <span className="span-filter mx-12">Filters</span>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.293 0.292969L5.99997 4.58597L1.70697 0.292969L0.292969 1.70697L5.99997 7.41397L11.707 1.70697L10.293 0.292969Z"
                          fill="#828282"
                        />
                      </svg>
                    </>
                  )}
              </button>
            </span>

            <span className="p-input-icon-left d-flex align-items-center">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search..."
                value={lazyParams.keyword}
                onInput={(e) => {
                  lazyParams = {
                    ...lazyParams,
                    skip: 0,
                    take: 25,
                    keyword: e.target.value,
                  };
                  setLazyParams(lazyParams);
                  getRiders();
                }}
              />
            </span>
            <CSVLink
              data={downlaodCsv}
              headers={headers}
              filename='Rdiers.csv'
              target='_blank'
              ref={CSVdownloadRef}
            />
            {/* {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'export') &&  */}
            <button
              className="btn btn-primary float-right mb-1 ml-12"
              // onClick={() => exportCSV(false)}
              onClick={() => downloadCsvFile()}
            >
              Export
            </button>
            {currentRoutePermissions.some(a => a.split('/')[a.split('/').length - 1] === 'add') && <button
              className="btn btn-primary float-right mb-1 ml-12"
              onClick={() => {
                setAddRider(true);
                setMinutes(0);
                setotp("");
                setSeconds(0);
                setSendotp(true);
                setMoblNo("");
              }}
            >
              {windowWidth ? <i className="fa fa-plus" /> : "+ Add "}
            </button>}
          </div>
        </>
      );
    },
    redirectIdTemplate = (rowData) => {
      return (
        <div className="text-left">
          {rowData?.profileImage ? (
            <img
              src={`${rowData?.profileImage}`}
              alt="ride"
              style={{
                marginRight: "15px",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={riderAvtSec}
              alt="ride"
              style={{
                marginRight: "15px",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          )}

          {rowData.userId && (
            permissions.find(a => a.includes('/rider-details/details') || a.includes('/rider-details/trip-history') || a.includes('/rider-details/topup-history')) ?
              <Link
                to={"/rider-details/details/" + rowData.id}
                className="dataFnt-size"
                style={{ color: "rgba(19, 181, 66, 1)" }}
              >
                {/* {rowData.userStatus === "A" ? <img src = {rideSource} alt = "ride" className="nav_icon" /> : <img src = {whatsappSource} alt = "ride" className="nav_icon" />}; */}

                <span className="dataFnt-size" style={{ color: "#13B542" }}>
                  {rowData.userId}
                </span>
              </Link>
              : <Link
                to={"/rider-details/details/" + rowData.id}
                className="dataFnt-size"
                style={{ color: "rgba(19, 181, 66, 1)" }}
              >
                {/* {rowData.userStatus === "A" ? <img src = {rideSource} alt = "ride" className="nav_icon" /> : <img src = {whatsappSource} alt = "ride" className="nav_icon" />}; */}

                <span className="dataFnt-size" style={{ color: "#13B542" }}>
                  {rowData.userId}
                </span>
              </Link>
          )}
        </div>
      );
    },


    header = renderHeader(),
    toggleFilter = () => {
      setShowFilter(!showFilter);
      setLazyParams({
        filters: {
          userType: 1,
        },
        sort: {
          field: "creationDate",
          order: -1,
        },
        take: 25,
        skip: 0,
        keyword: "",
        totalRecords: 0,
        userType: 1,
      });
      setStatus(null);
      getRiders();
    };



  const signUpSchma = Yup.object({
    idNumber: Yup.number().required("Please Enter your ID number"),
    dob: Yup.date().required("Please select date"),
    otp: Yup.string().required("Please enter your OTP number"),
  });

  function getOTP() {
    sendOTP()
    setEnableOTP(true)
  }

  // otp setting interval for time
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = () => {
    setMinutes(1);
    setSeconds(0);

    let text = moblNo.toString();
    text = text.replace("+", "");

    let MobileNo = text;
    const DataFilter = {
      mobileNo: MobileNo,
      reason: 4,
    };

    APIService.post("/sendotp", DataFilter)
      .then((res) => setTillId(res.data.data.tId))

      .catch((err) => {
        console.log(err);
      });


  };
  const resendOTP = () => {
    sendOTP();
    setMinutes(1);
    setSeconds(0);
  };

  function VerifingOTP(tillId) {
    let text = moblNo.toString();
    text = text.replace("+", "");
    let MobileNo = text;
    const VerifyOTP = {
      mobileNo: MobileNo,
      tId: tillId,
      otp: otp,
      // userId: idNo,
      // dateOfBirth: dat,
    };

    APIService.post("/verifyotp", VerifyOTP)
      .then((res) =>
        res.data.statusCode == 302
          ? (setMessage(false),
            toast("User is already registered!", {
              position: toast.POSITION.TOP_CENTER,
              className: "bg-danger text-white ",
            }))
          : (toast("User registered successfully", {
            position: toast.POSITION.TOP_CENTER,
            className: "bg-success text-white ",
          }),
            setMessage(true),
            setAddRider(false),
            getRiders()
          )
      )

      .catch((err) => {
        console.log(err);
      });
  }

  // get downlaod table csv data
  const downloadCsvFile = () => {


    APIService.post("admin/riders")
      .then((response) => {
        if (response.data && response.data.statusCode === 200) {

          const Response = response.data.data.riders;
          var filterResponse = Response.map((item) => {
            return (
              item.userStatus === "A"
                ? (item.userStatus = "Active")
                : item.userStatus == null
                  ? (item.userStatus = "Inactive")
                  : "Locked",
              // item.userStatus === "null"?item.userStatus='Inactive':'Inactive',
              (item.createdAt = moment(item.createdAt).format(
                "DD/MM/YYYY - hh:mm A"
              ))
              // ,
              // item.kycStatus === 1
              //   ? (item.kycStatus = "Yes")
              //   : item.kycStatus === 0
              //   ? (item.kycStatus = "No")
              //   : ""
              // item.kycStatus === 0?item.kycStatus='No':'No'
            );
          });

          setDownloadCsv(Response);
          setTimeout(() => {

            CSVdownloadRef.current.link.click();
          }, 500)

        }
      })
  }

  let calendarRef = useRef(null)
  const handleCalendarHide = () => setIsCalendarOpen(false)

  return (
    <>
      <div className="datatable-doc-demo mt-3">
        <div className="card">
          {/* <Tooltip target=".export-buttons>button" position="bottom" /> */}
          <DataTable
            ref={dt}
            value={riders}
            header={header}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            scrollable
            scrollHeight="470px"
            paginator
            emptyMessage="No Rider(s) found"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
            rowsPerPageOptions={[5, 10, 25, 50]}
            totalRecords={lazyParams.totalRecords}
            lazy={true}
            first={lazyParams.skip}
            rows={lazyParams.take}
            sortField={lazyParams.sort.field}
            sortOrder={lazyParams.sort.order}
            onPage={manageRiders}
            onSort={manageRiders}
            onFilter={manageRiders}
            loading={useSelector((state) => state.isGridLoader)}
            loadingIcon="fa fa-spinner"
          >
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              field="userId"
              header="ID"
              body={redirectIdTemplate}
              sortable
              filter={showFilter}
              filterField="userId"
              // filterElement={
              //   <InputText value={filterSearch} onChange={onSearchFilter}  placeholder="Search by id" />
              // }
              filterMatchMode="contains"
              filterPlaceholder="Search by id"
            />
            <Column
              className="text-left"
              headerClassName="text-left"
              style={{ fontSize: "12px", width: "190px" }}
              field="fullName"
              header="Full name"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by name"
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "160px" }}
              field="overallRating"
              header="Rating"
              body={
                (rider) => {
                  if (rider.overallRating == 0) {
                    return "--";
                  } else {
                    return ratingCount(Math.round(rider.overallRating));
                  }
                }

                // <Rating
                //   className="ratings pl-0 d-flex justify-content-center"
                //   fractions={2}
                //   emptySymbol="fa fa-star-o fa-2x star"
                //   fullSymbol="fa fa-star fa-2x star"
                //   initialRating={rider.overallRating}
                //   readonly
                // />
              }
              sortable
              filter={false}
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Loyalty Points"
              field="LoyaltyPoints"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="Mobile no."
              field="mobileNo"
              sortable
              filter={showFilter}
              filterPlaceholder="Search by mobile"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "250px" }}
              header="Date & Time"
              field="createdAt"
              // body={(rowData) => (
              //   <React.Fragment>
              //     <span className="dataFnt-size-date">
              //       {rowData.createdAt &&
              //         moment(rowData.createdAt).format("DD/MM/YYYY - hh:mm A")}
              //     </span>
              //   </React.Fragment>
              // )}
              sortable
              filter={showFilter}
              filterElement={
                <div className="d-inline-flex position-relative w-100 font-weight-bolder text-dark">
                  <Calendar
                    id="range"
                    className="mr-4 mb-2 w-100"
                    placeholder="Select date range"
                    dateFormat="dd/mm/yy"
                    value={creationDate}
                    hideOnDateTimeSelect={true}
                    onChange={(e) => {
                      if (e.value) {
                        setCreationDate(e.value);
                        if (e.value[0] && e.value[1]) {
                          lazyParams.filters["createdAt"] = [
                            moment(e.value[0]).format("YYYY-MM-DD"),
                            moment(e.value[1]).format("YYYY-MM-DD"),
                          ];
                          lazyParams.filters['userType'] = 1
                          setLazyParams(lazyParams);
                          setTimeout(() => {
                            getRiders();
                          }, 500);
                        }
                      } else {
                        delete lazyParams.filters["createdAt"];
                        setCreationDate([]);
                        lazyParams.filters['userType'] = 1
                        setLazyParams(lazyParams);
                        setTimeout(() => {
                          getRiders();
                        }, 500);
                      }
                    }}
                    showOnFocus={isCalendarOpen}
                    onHide={handleCalendarHide}
                    ref={calendarRef}
                    onFocus={()=> setTimeout(() => {
                      calendarRef.current.showOverlay()
                    }, 100)}
                    selectionMode="range"
                    showButtonBar
                    readOnlyInput
                  />
                  <span className="input-box-daterange-icon font-weight-bolder text-dark">
                    <CalendarRange />
                  </span>
                </div>
              }
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "180px" }}
              header="Completed rides"
              field="totalRides"
              body={(rider) => {
                const cancelled = rider?.ridesCancelled
                  ? rider?.ridesCancelled
                  : 0;
                return rider?.totalRides + cancelled;
              }}
            // sortable
            // filter={showFilter}
            // filterElement={
            //   <div className="d-inline-flex position-relative w-100">
            //     <InputNumber
            //       className="p-column-filter"
            //       placeholder="Search by rides"
            //       onChange={(e) => {
            //         if (e.value) {
            //           lazyParams.filters["totalRides"] = e.value;
            //         } else {
            //           delete lazyParams.filters["totalRides"];
            //         }
            //         setLazyParams(lazyParams);
            //         setTimeout(() => {
            //           getRiders();
            //         }, 500);
            //       }}
            //     />
            //   </div>
            // }
            // filterPlaceholder="Search by rides"
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "170px" }}
              header="KYC"
              field="kyc"
              body={(rider) => {
                return rider.kycStatus == 1 ? (
                  <span> Yes</span>
                ) : (
                  <span style={{ color: "rgba(235, 61, 61, 1)" }}> No</span>
                );
              }}
            />

            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "150px" }}
              field="userStatus"
              header="Status"
              // body={(rider) => {
              //   if (rider.userStatus === "A") {
              //     return <span className="status-text">Active</span>;
              //   } else if (rider.userStatus === "L") {
              //     return <span className="status-text-inactive">Locked</span>;
              //   } else {
              //     return <span className="status-text-inactive">Inactive</span>;
              //   }
              // }}
              sortable
              filter={showFilter}
              filterElement={
                <Dropdown
                  value={status}
                  options={statusOptions}
                  onChange={(event) => {
                    setStatus(event.value);
                    if (event.value) {
                      lazyParams.filters["status"] = event.value;
                    } else {
                      delete lazyParams.filters["status"];
                    }
                    lazyParams.filters['userType'] = 1
                    setLazyParams(lazyParams);
                    setTimeout(() => {
                      getRiders();
                    }, 500);
                  }}
                  placeholder="Status"
                  className="p-column-filter"
                  showClear
                />
              }
            />
            <Column
              className="text-center"
              style={{ fontSize: "12px", width: "145px" }}
              field="approved"
              header="Source"
              body={(rider) => {
                return rider.userStatus === "A" ? (
                  <img src={rideSource} alt="ride" className="nav_icon" />
                ) : (
                  <img src={whatsappSource} alt="ride" className="nav_icon" />
                );
              }}
              filter={false}
            />
          </DataTable>
        </div>
      </div>
      <Modal
        className="modal-promotion"
        show={showRider}
        size="md"
        onHide={addRiderHandleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Formik validationSchema={signUpSchma}>
          {({ handleBlur, setFieldValue, values }) => {
            return (
              <Form>
                <Modal.Header className="flex-modal-header">
                  <Modal.Title className="modal-title">
                    Add New Rider
                  </Modal.Title>
                  <div class="divclose" onClick={addRiderHandleClose}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body className="mt-24">
                  <Row className="justify-content-center">
                    <Col md={12}>
                      <div className="mb-4">
                        <CountryInput
                          setMoblNo={setMoblNo}
                          setOTPValidation={setOTPValidation}
                        />
                      </div>
                    </Col>
                    {enableOTP ? <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="otp"
                          type="text"
                          name="otp"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Enter OTP"
                          required

                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                        />
                        <label htmlFor="otp">Enter OTP</label>
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col> : <Col md={12}>
                      <div className="form-group form-label-group mb-4">
                        <Field
                          id="otp"
                          type="text"
                          name="otp"
                          className="shadow-none form-control modal-input br-10"
                          placeholder="Enter OTP"
                          required
                          disabled
                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                        />
                        <label htmlFor="otp">Enter OTP</label>
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="invalid-feedback text-danger"
                        />
                      </div>
                    </Col>
                    }

                    <Col md={12}>
                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Didn't recieve code?
                          {seconds > 0 || minutes > 0 ? (
                            <span className="ml-2 font-weight-bold text-dark">
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                              {setSendotp(false)}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        <p>
                          <span>
                            {sendotp ? (
                              OTPValidation ? (
                                <p className="resend" style={{ fontSize: '20px' }} onClick={() => getOTP()}>
                                  send OTP
                                </p>
                              ) : (
                                <p disabled className="resend font-weight-bolder" style={{ color: 'gray', fontSize: '20px' }}>
                                  send OTP
                                </p>
                              )
                            ) : !otp ? (
                              <p>
                                Didn't recieve code?
                                <span
                                  className="resend ml-2"
                                  onClick={resendOTP}
                                  style={{ fontSize: '20px' }}
                                >
                                  {" "}
                                  Resend OTP
                                </span>
                              </p>
                            ) : (
                              <p className="resend" onClick={resendOTP} style={{ fontSize: '20px' }}>
                                send OTP
                              </p>
                            )}
                          </span>
                        </p>
                      )}
                    </Col>
                    <Col md={12} className="mt-3">
                      {otp.length > 3 ? (
                        <Button
                          variant="primary"
                          //type="submit"
                          onClick={() => {
                            VerifingOTP(tillid);
                            setEnableOTP(false);
                          }}
                          className="btn-save btn-block "
                        >
                          Add Rider
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          //type="submit"
                          className="btn-save btn-block "
                          disabled
                        >
                          Add Rider
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        show={showMessage}
        onHide={handleCloseMessage}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
        className="modal-height modal-vc "
      >
        <Formik>
          {() => {
            return (
              <Form>
                <Modal.Header>
                  <Modal.Title className="modal-title"> </Modal.Title>
                  <div class="divclose" onClick={handleCloseMessage}>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  <Row
                    className="customheight"
                    style={{
                      height: "440px !important",
                      minHeight: "440px !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col xl={12} className="text-center my-auto custom-success">
                      <svg
                        class="custom-svg"
                        viewBox="0 0 26 26"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="currentColor"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            class="circle"
                            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                          />
                          <path
                            class="tick"
                            d="M6.5 13.5L10 17 l8.808621-8.308621"
                          />
                        </g>
                      </svg>

                      <h2 className="success-message mt-24">
                        New rider is added <br />
                        successfully
                      </h2>
                    </Col>
                  </Row>
                </Modal.Body>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default React.memo(connect()(RiderListingTable));
