import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TripHistoryTab from "../components/Riders/TripHistoryTab";
import PaymentHistory from "../components/Riders/PaymentHistory";
import RiderDetailsTab from "../components/Riders/RiderDetailsTab";
import Spendings from "../components/Riders/Spendings";
import APIService from "../services/APIService";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import TicketHistory from "../components/Riders/TicketHistory";

let RiderListingTableDetailPage = (props) => {
  let { id } = useParams(),
    [tab, setTab] = useState(null),
    [riderView, setRiderView] = useState([]),
    permissions = JSON.parse(atob(localStorage.getItem("access"))),
    pathname = props.location.pathname,
    getRider = () => {
      if (props.match.params.tab) {
        setTab(props.match.params.tab);
      } else {
        setTab("details");
      }
      // if (props.match.params.tab === "details" || props.match.params.tab === "payment-history" || props.match.params.tab === "topup-history") {
        props.dispatch({ type: "TOGGLE_LOADER", payload: true });
        APIService.get("admin/riders/" + id)
          .then((response) => {
            if (response.data && response.data.statusCode === 200) {
              setRiderView(response.data.data);
              props.dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
          })
          .catch(() =>
            props.dispatch({ type: "TOGGLE_LOADER", payload: false })
          );
      // }
    };

  useEffect(() => {
    getRider();
  }, []);

  return (
    <>
      <div className="rider-listing">
        <Link to="/riders" title="Back">
          <i className="fa fa-chevron-left back-arrow"></i>
        </Link>
        <span className="title">Riders</span>

        <Tabs
          id="controlled-tab-example"
          className="mt-30"
          activeKey={tab}
          onSelect={(tab) => {
            setTab(tab);
            props.history.push(`/rider-details/${tab}/${id}`);
          }}
        >
          {permissions.some(a => a.includes('/rider-details/details')) && <Tab eventKey="details" title="Rider Details">
            {tab === "details" && (
              <RiderDetailsTab
                rideViewData={riderView}
                dispatch={props?.dispatch}
                riderId={id}
              />
            )}
          </Tab>}
          {permissions.some(a => a.includes('/rider-details/trip-history')) && <Tab eventKey="trip-history" title="Trip Details">
            {tab === "trip-history" && <TripHistoryTab />}
          </Tab>}
          {/* <Tab eventKey="upcoming-scheduled" title="Upcoming Scheduled">
            {tab === "upcoming-scheduled" && <UpcomingScheduledTab />}
          </Tab> */}
          {permissions.some(a => a.includes('/rider-details/topup-history')) && <Tab eventKey="topup-history" title="Ticket History">
            {tab === "topup-history" && (
              <TicketHistory riderView={riderView} />
            )}
          </Tab>}

        </Tabs>
      </div>
    </>
  );
};

export default React.memo(connect()(RiderListingTableDetailPage));
