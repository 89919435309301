import React from 'react'
import riderAvtSec from "../../../assest/images/carimage.png";
const baseURL = process.env.REACT_APP_RIDE_A_RIDE_URL;



const CarInventoryHeader = ({inventoryData}) => {
    // console.log(inventoryData);
    const {image, manufacturer , model , model_year , category , seating_capacity , body_color , chassis_no , rental_amount } = inventoryData;
  return (
    <div className='d-flex w-100 justify-content-between px-5'>
    <div>
        <img src={baseURL + image?.url ?? riderAvtSec} alt='' style={{padding:"10px"}} width={"120px"} height={"120px"}/>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Manufacturer</span>
        <span className='font-weight-bold text-center'>{manufacturer || "-"} </span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Modal & Year</span>
        <span className='font-weight-bold'>{model} {model_year || "-"}</span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Category</span>
        <span className='font-weight-bold'>{category?.name || "-"}</span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Seating</span>
        <span className='font-weight-bold text-center'>{seating_capacity || "-"}</span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Body Color</span>
        <span className='font-weight-bold text-center'>{body_color || "-"}</span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Chassis No</span>
        <span className='font-weight-bold text-center'>{chassis_no || "-"}</span>
    </div>
    <div className='d-flex flex-column justify-content-center'>
        <span>Rental Amount</span>
        <span className='font-weight-bold text-center'>{rental_amount || "-"} SAR</span>
    </div>
    
    </div>
  )
}

export default CarInventoryHeader